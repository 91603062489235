import Headroom from 'headroom.js';

document.addEventListener("DOMContentLoaded", function() {

    const headroom = new Headroom(document.querySelector("#header"), {
        offset: 100, // Math.floor( window.screen.availHeight / 4 )
    });

    headroom.init();

});


document.addEventListener("DOMContentLoaded", function() {

    [].slice.call( document.querySelectorAll('.wp-block-media-text__scroll-down') ).forEach( (button) => {
        button.addEventListener('click', function(e) {
            window.scrollTo({
                top: window.outerHeight - 100, // 100% - header bar height
                left: 0,
                behavior: 'smooth'
            });
        })
    })
});
