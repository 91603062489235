document.addEventListener("DOMContentLoaded", function() {

    [].forEach.call(document.querySelectorAll('.schema-faq-section'), function (section, index) {

        const question = section.querySelector('.schema-faq-question');
        const answer = section.querySelector('.schema-faq-answer');
        
        question.setAttribute('role', 'button');
        question.setAttribute('tabindex', '1');
        answer.setAttribute('hidden', true);

        question.addEventListener('click', function (event) {
            event.preventDefault();
            question.parentElement.classList.toggle('is-open');
            question.setAttribute('aria-selected', !! question.parentElement.classList.contains('is-open'));
            if ( question.parentElement.classList.contains('is-open') ) {
                answer.removeAttribute('hidden');
            } else {
                answer.setAttribute('hidden', true);
            }
        });

        question.addEventListener('keydown', function (event) {
            
            if ( event.keyCode == 'Enter' ) {
                question.click();
            }
        });
    });

});
